import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ReactGA from 'react-ga4'

import 'src/i18n'
import 'src/libs/yup'

import { apiClient } from './api'

import App from './App'

import { GooglePlacesApiProvider } from 'src/context'

import { GA_TRACKING_CODE } from 'src/config'

import './styles/globals.scss'

/*ReactGA.initialize(GA_TRACKING_CODE, {
  gtagOptions: {
    send_page_view: false,
  },
})*/

const rootNode = document.getElementById('root')

if (rootNode) {
  const root = ReactDOM.createRoot(rootNode)

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={apiClient}>
        <GooglePlacesApiProvider>
          <App />
        </GooglePlacesApiProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  )
}
