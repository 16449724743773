import cx from 'classnames'

import styles from './label.module.scss'

interface LabelProps {
  className?: string
  size?: 'normal' | 'bigger'
}

const Label = (props: React.PropsWithChildren<LabelProps>) => {
  const { className, size = 'normal', children } = props

  return (
    <div className={cx(styles.label, styles[size], className)}>{children}</div>
  )
}

export default Label
