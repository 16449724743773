import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { FieldWrapper } from 'src/components'

import styles from './phone-input.module.scss'

import { IField } from 'src/interfaces'

const getPhoneWithMask = (str: string) => {
  return str
    .split('')
    .reduce((a, c, i) => {
      if (i == 6) a.push('-')
      a.push(c)
      if (i == 2) a.push('-')
      return a
    }, [] as string[])
    .join('')
}

const PhoneInput = (props: IField) => {
  const { name, placeholder } = props

  const { t } = useTranslation()

  const [field, meta, { setValue }] = useField({ name })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { defaultValue, value: originalValue } = event.currentTarget
    let phoneValue = originalValue

    if (phoneValue.length > 12) {
      phoneValue = phoneValue.substring(0, phoneValue.length - 1)
    }

    phoneValue = phoneValue.replace(/[^0-9.]/g, '')

    if (
      originalValue.length < defaultValue.length &&
      defaultValue[defaultValue.length - 1] === '-'
    ) {
    } else {
      phoneValue = getPhoneWithMask(phoneValue)
    }

    setValue(phoneValue || '')
  }

  return (
    <FieldWrapper {...props}>
      <input
        className={cx(
          styles.basicInput,
          meta.error && meta.touched && styles.error,
        )}
        placeholder={placeholder ? t(`placeholder.${placeholder}`) : undefined}
        {...field}
        onChange={handleChange}
      />
    </FieldWrapper>
  )
}

export default PhoneInput
