import { createContext, useState, useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

import { GOOGLE_MAPS_API_KEY } from 'src/config'

interface IGooglePlacesApiContext {
  findPlace: (
    input: string,
  ) => Promise<google.maps.places.AutocompleteResponse | undefined>
  getPlaceById: (
    placeId: string,
    callback: (result: google.maps.places.PlaceResult | null) => void,
  ) => void
}

export const GooglePlacesApiContext = createContext<
  IGooglePlacesApiContext | undefined
>(undefined)

export const GooglePlacesApiProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null)
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)

  useEffect(() => {
    ;(async () => {
      await new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
        language: 'en',
      })
        .load()
        .then(() => {
          setPlacesService(
            new google.maps.places.PlacesService(document.createElement('div')),
          )
          setAutocompleteService(
            new window.google.maps.places.AutocompleteService(),
          )
          setIsLoading(false)
        })
    })()
  }, [])

  const findPlace = async (input: string) => {
    return await autocompleteService?.getPlacePredictions({
      input,
      componentRestrictions: { country: 'us' },
    })
  }

  const getPlaceById = (
    placeId: string,
    callback: (result: google.maps.places.PlaceResult | null) => void,
  ) => {
    placesService?.getDetails(
      {
        placeId,
        fields: ['address_components', 'adr_address'],
      },
      callback,
    )
  }

  return (
    <GooglePlacesApiContext.Provider
      value={{
        findPlace,
        getPlaceById,
      }}
    >
      {!isLoading ? children : null}
    </GooglePlacesApiContext.Provider>
  )
}
