import { ILeadMutationData, IFieldDependsOn } from 'src/interfaces'

const isEmptyArray = (fieldValue: any, ruleValue: boolean) => {
  if (Array.isArray(fieldValue)) {
    return !(fieldValue as Array<any>).length === ruleValue
  }

  return false
}

const included = (fieldValue: any, ruleValue: string[]) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue.find((s: string) => ruleValue.includes(s))
  }

  return false
}

const notIncluded = (fieldValue: any, ruleValue: string[]) => {
  if (Array.isArray(fieldValue)) {
    return !fieldValue.find((s: string) => ruleValue.includes(s))
  }

  return false
}

const getDependingCheck = (
  formikValues: ILeadMutationData,
  dependings?: {
    [Key in keyof ILeadMutationData]?: IFieldDependsOn
  },
) => {
  if (!dependings) {
    return true
  }

  return Object.entries(dependings).every(([fieldKey, fieldRules]) => {
    return Object.entries(fieldRules).every(
      ([ruleName, ruleValue]: [name: string, value: any]) => {
        const fieldValue = formikValues[fieldKey as keyof ILeadMutationData]

        switch (ruleName) {
          case 'emptyArray':
            return isEmptyArray(fieldValue, ruleValue)
          case 'included':
            return included(fieldValue, ruleValue)
          case 'notIncluded':
            return notIncluded(fieldValue, ruleValue)
        }
      },
    )
  })
}

export default getDependingCheck
