import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import cx from 'classnames'

import { FieldWrapper } from 'src/components'

import { useGooglePlacesApi } from 'src/hooks'

import styles from './google-places-input.module.scss'

import { IField } from 'src/interfaces'

const GooglePlacesInput = (props: IField) => {
  const { name, placeholder, onChange } = props

  const selectRef = useRef(null)

  const { t } = useTranslation()

  const googlePlaceApi = useGooglePlacesApi()

  const [field, meta, { setValue }] = useField({ name })

  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const [placesIsLoading, setPlacesIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )
          if (!isClickInside) {
            setOptions([])
            setPlacesIsLoading(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setOptions([])
          setPlacesIsLoading(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.currentTarget
    if (currentValue.length > 0) {
      if (!placesIsLoading) {
        setPlacesIsLoading(true)
        googlePlaceApi?.findPlace(event.currentTarget.value).then((results) => {
          if (results?.predictions) {
            const newOptions = results.predictions
              .filter((p) => p.types.includes('street_address'))
              .map((p) => ({
                value: p.place_id,
                label: p.description,
              }))
            setOptions(newOptions)
            setPlacesIsLoading(false)
          }
        })
      }

      setValue(currentValue)
    } else {
      if (onChange) {
        setValue(currentValue)
        onChange(null)
      }
    }
  }

  const handleSelect = (option: { value: string; label: string }) => {
    googlePlaceApi?.getPlaceById(option.value, (result) => {
      setValue(option.label)
      setOptions([])

      if (onChange) {
        onChange(result)
      }
    })
  }

  return (
    <FieldWrapper {...props}>
      <div className={styles.wrapper} ref={selectRef}>
        <input
          className={cx(
            styles.basicInput,
            meta.error && meta.touched && styles.error,
          )}
          placeholder={
            placeholder ? t(`placeholder.${placeholder}`) : undefined
          }
          {...field}
          onChange={handleChange}
        />
        {options.length ? (
          <div className={styles.basicOptions}>
            {options?.map((o) => (
              <div key={o.value} onClick={() => handleSelect(o)}>
                {o.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </FieldWrapper>
  )
}

export default GooglePlacesInput
