export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'ईमेल',
    firstName: 'पहला नाम',
    lastName: 'उपनाम',
    carCompanies: 'आप किस ऐप (ऐप्स) के लिए काम करते हैं?',
    gender: 'लिंग',
    phoneNumber: 'फ़ोन नंबर',
    dateOfBirth: 'जन्म की तारीख',
    streetAddress: 'गली का पता',
    apartment: 'उपयुक्त, इकाई, सूट, या मंजिल #',
    city: 'शहर',
    stateSelectHome: 'राज्य',
    zipCode: 'पिन कोड',
    driversLicenseNumber: 'ड्राइवर का लाइसेंस #',
    driversLicenseNumberСonfirm: 'ड्राइवर का लाइसेंस फिर से दर्ज करें #',
    driversId: 'ID #',
    driversIdСonfirm: 'आईडी फिर से दर्ज करें #',
    tlcNumber: 'TLC #',
    tlcNumberСonfirm: 'टीएलसी # फिर से दर्ज करें',
    howYouDeliver: 'आप कैसे पहुंचाते हैं',
    whatStateDoYouDriveIn: 'आप किस राज्य में ड्राइव करते हैं',
  },
  placeholder: {
    email: 'अपना ईमेल दर्ज करें',
    firstName: 'अपना नाम दर्ज करें',
    lastName: 'अपना अंतिम नाम दर्ज करें',
    gender: 'लिंग चुनें',
    phoneNumber: '000-000-0000',
    streetAddress: 'अपनी गली का पता दर्ज करें',
    apartment: 'प्रवेश करना #',
    city: 'शहर दर्ज करें',
    stateSelectHome: 'राज्य चुनें',
    zipCode: 'अपना ज़िप कोड दर्ज करें',
    driversLicenseNumber: 'अपना ड्राइविंग लाइसेंस दर्ज करें',
    driversLicenseNumberСonfirm: 'ड्राइवर का लाइसेंस फिर से दर्ज करें #',
    driversId: 'अपनी आईडी दर्ज करें',
    driversIdСonfirm: 'आईडी फिर से दर्ज करें #',
    tlcNumber: 'अपना टीएलसी दर्ज करें #',
    tlcNumberСonfirm: 'टीएलसी # फिर से दर्ज करें',
    other: 'अन्य',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'पुरुष',
      Female: 'मादा',
      'Prefer not to say': 'चुप रहना पसंद करूंगा',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'गाड़ी', bike: 'साइकिल', onFoot: 'पैरों पर' },
  },
  button: { nextStep: 'अगला कदम', submit: 'प्रस्तुत करना' },
  error: {
    required: 'अनिवार्य उपस्थिति',
    email: 'ईमेल होना चाहिए',
    phone: 'अवैध फोन नंबर',
    date: 'अमान्य तिथि',
    driverAge: { dateOfBirth: 'आपकी आयु 16 से 100 वर्ष के बीच होनी चाहिए' },
    name: 'एक मान्य नाम होना चाहिए',
    streetAddress: 'एक मान्य गली का पता होना चाहिए',
    city: 'एक वैध शहर होना चाहिए',
    zipCode: 'एक मान्य ज़िप कोड होना चाहिए',
    driverLicense: 'वैध ड्राइवर लाइसेंस होना चाहिए',
    atLeast1: 'कम से कम एक विकल्प चुनें',
    equalTo: {
      driversLicenseNumberСonfirm: 'ड्राइवर का लाइसेंस मेल खाना चाहिए',
      driversIdСonfirm: 'आईडी का मिलान होना चाहिए',
      tlcNumberСonfirm: 'टीएलसी का मिलान होना चाहिए',
    },
  },
  other: {
    step: 'कदम',
    oneMoreStep: 'एक और क़दम',
    toAccessTheBenefits: 'लाभों तक पहुँचने के लिए',
    IDGIsHereForYou:
      'आईडीजी यहाँ आपके लिए है! ऐप वर्कर अधिकारों के लिए लड़ने के लिए हमसे जुड़ें!\nध्यान दें कि डिलीवरी श्रमिकों के लिए अभी तक कोई लाभ नहीं है।',
    byCompletingTheInformation:
      'ऊपर दी गई जानकारी को पूरा करके और "सबमिट" पर क्लिक करके, आप पुष्टि करते हैं कि आपने हमारी <termsOfUseLink> उपयोग की शर्तें</termsOfUseLink> को पढ़, समझ लिया है और उनसे सहमत हैं। इसके अलावा, आप सहमत हैं कि हम अपनी <privacyPolicyLink>गोपनीयता नीति</privacyPolicyLink> में बताए अनुसार आपकी जानकारी का उपयोग और खुलासा कर सकते हैं।',
    goodJob: 'सेवा की शर्तें।',
    submitAndGetBenefits: 'गोपनीयता नीति।',
    congratulations: 'बधाई हो',
    weWillBeInTouchSoon: 'हम जल्द ही संपर्क में आएँगे!',
    successThanks: 'हमसे जुडने के लिए तुम्हारा शुक्रिया!',
    successMesage: 'हमें आपकी जानकारी मिल गई है। हम जल्द ही संपर्क में आएँगे!',
    useBenefits: 'अपने लाभ का प्रयोग करें',
    contactUs: 'संपर्क करें',
  },
}
