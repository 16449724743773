import Tabs from './Tabs'
import Footer from './Footer'
import SuccessBlock from './SuccessBlock'

import styles from './basic-template.module.scss'
import SystemMessages from './SystemMessages/SystemMessages'

interface BasicTemplateProps {
  loading: boolean
  stepsCount: number
  currentStepIndex: number
  isSuccess: boolean
  onNext: () => void
}

const BasicTemplate = (props: React.PropsWithChildren<BasicTemplateProps>) => {
  const { loading, stepsCount, currentStepIndex, isSuccess, onNext, children } =
    props

  return (
    <div className={styles.wrapper}>
      <SystemMessages />
      <div className={styles.inner}>
        {!isSuccess ? (
          <>
            <div className={styles.container}>
              <p className={styles.title}>
                Interested in going to a rally or lobby day?
              </p>
              <p className={styles.subtitle}>
                Contact our organizing team via text message at 978-274-7087 or
                complete the form below.
              </p>
            </div>

            {children}

            <Footer
              loading={loading}
              stepsCount={stepsCount}
              currentStepIndex={currentStepIndex}
              onNext={onNext}
            />
          </>
        ) : (
          <SuccessBlock />
        )}
      </div>
    </div>
  )
}

export default BasicTemplate
