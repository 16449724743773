import { Button, FooterItem } from 'src/components'
import { Done } from 'src/components/Illustrations/Illustrations'
import cx from 'classnames'
import { useField, useFormikContext } from 'formik'

import styles from './success-block.module.scss'
import { getUrlParamsValues } from 'src/helpers'
import { useEffect } from 'react'
import { typeOfDeliveryApp } from 'src/App'
import { useState } from 'react'

const SuccessBlock = () => {
  const { contracts } = getUrlParamsValues()
  const { values }: any = useFormikContext()
  const [isDeliveryApp, setIsDeliveryApp] = useState<boolean>(false)

  const typeApp = typeOfDeliveryApp(values?.carCompanies)

  useEffect(() => {
    if (typeApp === 'Delivery') {
      setIsDeliveryApp(true)
    }
  }, [values])

  const handleContactUs = () => {
    if (contracts.includes('00000101')) {
      window.location.href = 'https://ny.driversguild.org/contact-us/'
      return
    }
    if (contracts.includes('00000102')) {
      window.location.href = 'https://nj.driversguild.org/contact-us/'
      return
    }
    if (contracts.includes('00000103')) {
      window.location.href = 'https://ma.driversguild.org/contact-us/'
      return
    }
    if (contracts.includes('00000104')) {
      window.location.href = 'https://ct.driversguild.org/contact-us/'
      return
    }
    if (contracts.includes('00000105')) {
      window.location.href = 'https://il.driversguild.org/contact-us/'
      return
    }
    window.location.href = 'https://driversguild.org'
    return
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.containerImg}>
          <Done />
        </div>
        <div className={cx(styles.sentText, styles.marginTen)}>
          Message sent!
        </div>
        <div className={cx(styles.thankYouText)}>
          Thanks! We’ll get back to you soon
        </div>
        <div className={styles.containerButtons}></div>
      </div>
    </div>
  )
}

export default SuccessBlock
