export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    carCompanies: 'Pour quelle(s) application(s) travaillez-vous ?',
    gender: 'Le genre',
    phoneNumber: 'Numéro de téléphone',
    dateOfBirth: 'Date de naissance',
    streetAddress: 'Adresse de rue',
    apartment: 'App., unité, suite ou étage #',
    city: 'Ville',
    stateSelectHome: 'État',
    zipCode: 'Code postal',
    driversLicenseNumber: 'Le permis de conduire #',
    driversLicenseNumberСonfirm:
      'Entrez à nouveau le numéro de permis de conduire',
    driversId: 'ID #',
    driversIdСonfirm: "Entrez à nouveau le numéro d'identification",
    tlcNumber: 'TLC #',
    tlcNumberСonfirm: 'Entrez à nouveau le numéro TLC',
    howYouDeliver: 'Comment vous livrez',
    whatStateDoYouDriveIn: 'Dans quel état conduisez-vous',
  },
  placeholder: {
    email: 'Entrer votre Email',
    firstName: 'Entrez votre nom',
    lastName: 'Entrez votre nom de famille',
    gender: 'Sélectionnez le sexe',
    phoneNumber: '000-000-0000',
    streetAddress: 'Entrez votre adresse municipale',
    apartment: 'Entrer #',
    city: 'Entrez la ville',
    stateSelectHome: "Sélectionnez l'état",
    zipCode: 'Entrez votre code postal',
    driversLicenseNumber: 'Entrez votre permis de conduire',
    driversLicenseNumberСonfirm:
      'Entrez à nouveau le numéro de permis de conduire',
    driversId: 'Entrer votre identifiant',
    driversIdСonfirm: "Entrez à nouveau le numéro d'identification",
    tlcNumber: 'Entrez votre numéro TLC',
    tlcNumberСonfirm: 'Entrez à nouveau le numéro TLC',
    other: 'Autre',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'Homme',
      Female: 'Femelle',
      'Prefer not to say': 'Je préfère ne pas le dire',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'Auto', bike: 'Vélo', onFoot: 'À pied' },
  },
  button: { nextStep: "L'étape suivante", submit: 'Soumettre' },
  error: {
    required: 'Presence obligatoire',
    email: 'Doit être un e-mail',
    phone: 'Numéro de téléphone invalide',
    date: 'Date invalide',
    driverAge: { dateOfBirth: 'Vous devez avoir entre 16 et 100 ans' },
    name: 'Doit être un nom valide',
    streetAddress: 'Doit être une adresse municipale valide',
    city: 'Doit être une ville valide',
    zipCode: 'Doit être un code postal valide',
    driverLicense: 'Doit être un permis de conduire valide',
    atLeast1: 'Sélectionnez au moins une option',
    equalTo: {
      driversLicenseNumberСonfirm: 'Le permis de conduire doit correspondre',
      driversIdСonfirm: "L'identifiant doit correspondre",
      tlcNumberСonfirm: 'Le TLC doit correspondre',
    },
  },
  other: {
    step: 'Marcher',
    oneMoreStep: 'un pas de plus',
    toAccessTheBenefits: 'pour accéder aux avantages',
    IDGIsHereForYou:
      "IDF est là pour vous ! Rejoignez-nous pour lutter pour les droits des travailleurs de l'application !\nNotez qu'il n'y a pas encore d'avantages pour les livreurs.",
    byCompletingTheInformation:
      'En complétant les informations ci-dessus et en cliquant sur "Soumettre", vous confirmez que vous avez lu, compris et accepté nos <termsOfUseLink> Conditions d\'utilisation</termsOfUseLink>. De plus, vous avez accepté que nous puissions utiliser et divulguer vos informations comme indiqué dans notre <privacyPolicyLink>Politique de confidentialité</privacyPolicyLink>',
    goodJob: 'Bon travail!',
    submitAndGetBenefits: 'Soumettez le formulaire pour accéder aux avantages!',
    congratulations: 'Toutes nos félicitations',
    weWillBeInTouchSoon: 'Nous serons en contact bientot!',
    successThanks: 'Merci de nous avoir rejoint!',
    successMesage:
      'Nous avons reçu votre information. Nous serons en contact bientot!',
    useBenefits: 'Utilisez vos avantages',
    contactUs: 'Nous contacter',
  },
}
