import { useMutation } from 'react-query'
import { api } from 'src/api'

import { IFormDataDto } from '../interfaces/form'

const useSendFormData = () =>
  useMutation(async (data: IFormDataDto) => api.put('/v1/forms', data), {
    onSuccess: (result) => {
      if (result && result.data) {
        console.log(result.data)
      }
    },
  })

export default useSendFormData
