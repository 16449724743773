export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'ইমেইল',
    firstName: 'নামের প্রথম অংশ',
    lastName: 'নামের শেষাংশ',
    carCompanies: 'আপনি কোন অ্যাপ্লিকেশনের জন্য কাজ করেন?',
    gender: 'লিঙ্গ',
    phoneNumber: 'ফোন নম্বর',
    dateOfBirth: 'জন্ম তারিখ',
    streetAddress: 'রাস্তার ঠিকানা',
    apartment: 'অ্যাপার্ট, ইউনিট, স্যুট বা মেঝে #',
    city: 'শহর',
    stateSelectHome: 'রাষ্ট্র',
    zipCode: 'জিপ কোড',
    driversLicenseNumber: 'চালকের লাইসেন্স #',
    driversLicenseNumberСonfirm: 'ড্রাইভিং লাইসেন্স পুনরায় প্রবেশ করান #',
    driversId: 'ID #',
    driversIdСonfirm: 'আইডি # পুনরায় লিখুন',
    tlcNumber: 'TLC #',
    tlcNumberСonfirm: 'TLC # পুনরায় প্রবেশ করুন',
    howYouDeliver: 'আপনি কিভাবে বিতরণ',
    whatStateDoYouDriveIn: 'আপনি কোন রাজ্যে গাড়ি চালান',
  },
  placeholder: {
    email: 'তুমার ইমেইল প্রবেশ করাও',
    firstName: 'আপনার নাম প্রবেশ করুন',
    lastName: 'আপনার শেষ নাম লিখুন',
    gender: 'লিংগ নির্বাচন',
    phoneNumber: '000-000-0000',
    streetAddress: 'আপনার রাস্তার ঠিকানা লিখুন',
    apartment: '# লিখুন',
    city: 'শহরে প্রবেশ করুন',
    stateSelectHome: 'রাজ্য নির্বাচন কর',
    zipCode: 'আপনার জিপ কোড প্রদান করুন',
    driversLicenseNumber: 'আপনার ড্রাইভিং লাইসেন্স লিখুন',
    driversLicenseNumberСonfirm: 'ড্রাইভিং লাইসেন্স পুনরায় প্রবেশ করান #',
    driversId: 'আপনার আইডি লিখুন',
    driversIdСonfirm: 'আইডি # পুনরায় লিখুন',
    tlcNumber: 'আপনার TLC # লিখুন',
    tlcNumberСonfirm: 'TLC # পুনরায় প্রবেশ করুন',
    other: 'অন্যান্য',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'পুরুষ',
      Female: 'মহিলা',
      'Prefer not to say': 'না বলতে পছন্দ করেন',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'গাড়ি', bike: 'বাইক', onFoot: 'হেঁটে' },
  },
  button: { nextStep: 'পরবর্তী পর্ব', submit: 'জমা দিন' },
  error: {
    required: 'উপস্থিত থাকতে হবে',
    email: 'ইমেইল হতে হবে',
    phone: 'অবৈধ ফোন নম্বর',
    date: 'অবৈধ তারিখ',
    driverAge: { dateOfBirth: 'আপনার বয়স 16 থেকে 100 বছরের মধ্যে হতে হবে' },
    name: 'একটি বৈধ নাম হতে হবে',
    streetAddress: 'একটি বৈধ রাস্তার ঠিকানা হতে হবে',
    city: 'একটি বৈধ শহর হতে হবে',
    zipCode: 'একটি বৈধ জিপ কোড হতে হবে',
    driverLicense: 'একটি বৈধ ড্রাইভার লাইসেন্স হতে হবে',
    atLeast1: 'অন্তত একটি বিকল্প নির্বাচন করুন',
    equalTo: {
      driversLicenseNumberСonfirm: 'ড্রাইভিং লাইসেন্স অবশ্যই মিলবে',
      driversIdСonfirm: 'আইডি অবশ্যই মিলবে',
      tlcNumberСonfirm: 'TLC অবশ্যই মিলবে',
    },
  },
  other: {
    step: 'ধাপ',
    oneMoreStep: 'আর এক ধাপ',
    toAccessTheBenefits: 'সুবিধা অ্যাক্সেস করতে',
    IDGIsHereForYou:
      'IDG আপনার জন্য এখানে! অ্যাপ কর্মীদের অধিকারের জন্য লড়াই করতে আমাদের সাথে যোগ দিন!\nউল্লেখ্য যে ডেলিভারি কর্মীদের জন্য এখনো কোনো সুবিধা নেই।',
    byCompletingTheInformation:
      'উপরের তথ্যটি সম্পূর্ণ করে এবং "জমা দিন" এ ক্লিক করে আপনি নিশ্চিত করেন যে আপনি আমাদের <termsOfUseLink> ব্যবহারের শর্তাবলী</termsOfUseLink> পড়েছেন, বুঝেছেন এবং সম্মত হয়েছেন। এছাড়াও, আপনি সম্মত হয়েছেন যে আমরা আমাদের <privacyPolicyLink>গোপনীয়তা নীতি</privacyPolicyLink>-এ বর্ণিত আপনার তথ্য ব্যবহার এবং প্রকাশ করতে পারি।',
    goodJob: 'সাবাশ!',
    submitAndGetBenefits: 'সুবিধা পেতে ফর্ম জমা দিন!',
    congratulations: 'অভিনন্দন',
    weWillBeInTouchSoon: 'আমরা খুব শীঘ্রই যোগাযোগ করা হবে!',
    successThanks: 'ধন্যবাদ আমাদের সাথে যোগদান করার জন্য!',
    successMesage: 'আমরা আপনার তথ্য পেয়েছি. আমরা খুব শীঘ্রই যোগাযোগ করা হবে!',
    useBenefits: 'আপনার সুবিধা ব্যবহার করুন',
    contactUs: 'যোগাযোগ করুন',
  },
}
