import axios from 'axios'
import { appLocalStorage } from 'src/libs'

import { API_BASE_URL } from 'src/config'

const token = appLocalStorage.token

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: token && `Bearer ${token}`,
  },
})

export default api
