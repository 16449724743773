import { useState } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { FieldWrapper } from 'src/components'

import CheckItem from './CheckItem'
import OtherInput from './OtherInput'

import styles from './checks.module.scss'

import { IField } from 'src/interfaces'

const Checks = (props: IField) => {
  const { name, withOther, options, align } = props

  const { t } = useTranslation()

  const [{ value: fieldValue }, meta, { setValue }] = useField({ name })

  const [otherValues, setOtherValues] = useState<string[] | null>(
    (() => {
      const initialOthers = fieldValue.filter((v: string) =>
        v.includes('__other__'),
      )
      if (initialOthers.length) {
        return initialOthers.map((o: string) => o.replace('__other__', ''))
      }

      return null
    })(),
  )

  const handleToggle = (value: string) => {
    const isSelected = fieldValue.includes(value)
    setValue(
      !isSelected
        ? [...fieldValue, value]
        : fieldValue.filter((v: string) => v !== value),
    )
  }

  const handleAddOther = (value: string) => {
    setOtherValues([...(otherValues || []), value])
    handleToggle(`__other__${value}`)
  }

  const handleRemove = (value: string) => {
    setOtherValues([...(otherValues?.filter((o) => o !== value) ?? [])])
    setValue([...fieldValue.filter((v: string) => v !== value)])
  }

  return (
    <FieldWrapper {...props}>
      <div className={cx(styles.wrapper, align === 'center' && styles.center)}>
        {options?.map((o) => (
          <CheckItem
            key={o.value}
            className={styles.checkItem}
            selected={fieldValue.includes(o.value)}
            onClick={() => handleToggle(o.value)}
          >
            {t(`options.${name}.${o.label}`)}
          </CheckItem>
        ))}

        {otherValues?.length
          ? otherValues?.map((o, i) => (
              <CheckItem
                key={i}
                className={styles.checkItem}
                selected={fieldValue.includes(`__other__${o}`)}
                isOther={true}
                onClick={() => handleToggle(`__other__${o}`)}
                onRemove={() => handleRemove(o)}
              >
                {o}
              </CheckItem>
            ))
          : null}

        <OtherInput onAdd={handleAddOther} />
      </div>
    </FieldWrapper>
  )
}

export default Checks
