import { APP_IS_TEMPORARILY_UNAVAILABLE } from 'src/config'

import { ISystemMessage } from './interface'

//TODO remove this file after all messages are moved to the database

// example:
//
// [
//     {
//         id: 'test',
//         message: 'This is a test alert message',
//         type: 'alert'
//     },
//     {
//         id: 'test2',
//         message: 'This is a test alert message 2',
//         type: 'alert'
//     },
//     {
//         id: 'exodus',
//         message: 'Under maintenance from Jan 10th 8pm to Jan 11th 8am',
//         type: 'blocking_message'
//     }
// ]

export const SYSTEM_MESSAGES: ISystemMessage[] = !APP_IS_TEMPORARILY_UNAVAILABLE ? [] : [
  {
    id: 'exodus-blocking',
    message:
      'Our system is performing a scheduled update. We apologize for the inconvenience. If you need help, please text us at <strong>978-274-7087</strong>. <br/>Thank you',
    type: 'blocking_message',
  },
]
