import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './check-item.module.scss'

interface CheckItemProps {
  className?: string
  selected?: boolean
  isOther?: boolean
  onClick: () => void
  onRemove?: () => void
}

const CheckItem = (props: React.PropsWithChildren<CheckItemProps>) => {
  const {
    className,
    selected,
    isOther,
    onClick,
    onRemove = () => false,
    children,
  } = props

  return (
    <div
      className={cx(styles.wrapper, selected && styles.selected, className)}
      onClick={onClick}
    >
      <span className={styles.circle} />
      {children}

      {isOther ? (
        <span className={styles.close} onClick={onRemove}>
          <Icons.Plus />
        </span>
      ) : null}
    </div>
  )
}

export default CheckItem
