import { useContext } from 'react'

import { GooglePlacesApiContext } from 'src/context'

const useGooglePlacesApi = () => {
  const context = useContext(GooglePlacesApiContext)

  return context
}

export default useGooglePlacesApi
